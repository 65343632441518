import { cva } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(
  [
    'data-[has-state="false"]:disabled:bg-disabled-strong',
    'data-[has-state="false"]:disabled:text-on-disabled-strong',
    'data-[has-state="false"]:disabled:shadow-none',
    'disabled:cursor-not-allowed',
    'flex',
    'flex-row',
    'justify-center',
    'items-center',
    'text-center',
    'relative',
    'no-underline',
    'font-body',
    'font-semibold',
    'cursor-pointer',
    'rounded-full',
    'whitespace-nowrap',
    'opacity-100',
    'duration-300',
    'ease-in-out',
    'outline-transparent',
    'focus-visible:outline',
    'focus-visible:outline-4',
    'focus-visible:outline-offset-2',
    'focus-visible:outline-interactive-focused',
  ],
  {
    variants: {
      size: {
        large: 'px-8 py-4 text-100 leading-125',
        default: 'px-6 py-3 text-87.5 leading-100',
        compact: 'px-4 py-2 text-87.5 leading-100',
      },
      variant: {
        primary: [
          'bg-button-primary',
          'text-on-primary',
          'data-[has-state="false"]:hover:bg-button-primary-hover',
          'data-[has-state="false"]:active:bg-button-primary-active',
        ],
        secondary: [
          'bg-transparent',
          'text',
          'data-[has-state="false"]:hover:bg-button-secondary-hover',
          'data-[has-state="false"]:active:bg-button-secondary-active',
        ],
        tertiary: [
          'bg-button-tertiary',
          'text',
          'data-[has-state="false"]:hover:bg-button-tertiary-hover',
          'data-[has-state="false"]:active:bg-button-tertiary-active',
        ],
        brand: [
          'bg-button-brand',
          'text-on-brand',
          'data-[has-state="false"]:hover:bg-button-brand-hover',
          'data-[has-state="false"]:active:bg-button-brand-active',
        ],
        outline: [
          'text',
          'bg-transparent',
          'shadow-[inset_0_0px_0px_2px_theme(colors.primary.DEFAULT)]',
          'data-[has-state="false"]:hover:bg-button-outline-hover',
          'data-[has-state="false"]:active:bg-button-outline-active',
        ],
        subtle: [
          'bg-transparent',
          'text',
          'border-transparent',
          'data-[has-state="false"]:hover:bg-transparent',
          'data-[has-state="false"]:hover:border-transparent',
          'data-[has-state="false"]:active:bg-transparent',
          'data-[has-state="false"]:active:border-transparent',
          'rounded-none',
          'py-0.5',
          'px-0.5',
          'data-[has-state="false"]:disabled:bg-transparent',
          'data-[has-state="false"]:disabled:text-disabled',
        ],
        positive: [
          'bg-button-positive',
          'text-on-positive',
          'data-[has-state="false"]:hover:bg-button-positive-hover',
          'data-[has-state="false"]:active:bg-button-positive-active',
        ],
        negative: [
          'bg-button-negative',
          'text-on-negative',
          'data-[has-state="false"]:hover:bg-button-negative-hover',
          'data-[has-state="false"]:active:bg-button-negative-active',
        ],
        negativeWeak: [
          'bg-button-negative-weak',
          'text-on-negative-weak',
          'data-[has-state="false"]:hover:bg-button-negative-weak-hover',
          'data-[has-state="false"]:active:bg-button-negative-weak-active',
        ],
      },
    },
    defaultVariants: {
      variant: 'tertiary',
      size: 'default',
    },
  },
);
