import * as React from 'react';
import { Text } from '../text';
import { PromptGeneric } from './PromptGeneric';
import type { PromptGenericT } from './PromptGeneric';

export interface PromptT extends Omit<PromptGenericT, 'title'> {
  children: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
}

export const Prompt = ({
  title,
  children,
  actions,
  onDismiss,
  dismissLabel,
  layer,
  theme,
  className,
  ...props
}: PromptT) => {
  return (
    <PromptGeneric
      theme={theme}
      onDismiss={onDismiss}
      dismissLabel={dismissLabel}
      layer={layer}
      actions={actions}
      className={className}
      {...props}
    >
      {title && typeof title === 'string' ? (
        <Text
          className={`${onDismiss ? 'pr-8' : 'pr-0'} mb-4 text-125 leading-snug font-semibold text`}
          as="h1"
        >
          {title}
        </Text>
      ) : (
        title
      )}

      {typeof children === 'string' ? (
        <Text as="p" className="mb-4 leading-normal ">
          {children}
        </Text>
      ) : (
        children
      )}
    </PromptGeneric>
  );
};
