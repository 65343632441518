import React, { forwardRef } from 'react';
import type { LinkProps } from 'next/link';
import NextLink from 'next/link';
import type { ClickableT as RagnarClickableT } from '@mentimeter/ragnar-ui';
import { Clickable as RagnarClickable } from '@mentimeter/ragnar-ui';
import { getRouteInternally } from './getRouteInternally';

export interface LinkClickableT
  extends Omit<RagnarClickableT, 'href'>,
    Pick<LinkProps, 'href'> {
  download?: string;
}

export const LinkClickable = forwardRef<HTMLAnchorElement, LinkClickableT>(
  ({ as, children, href, ...rest }, ref) => {
    const { shouldRouteInternally, internalLink } = getRouteInternally(
      href.toString(),
    );
    if (shouldRouteInternally && !rest.download) {
      return (
        <RagnarClickable {...rest} as={NextLink} href={internalLink} ref={ref}>
          {children}
        </RagnarClickable>
      );
    }
    return (
      <RagnarClickable {...rest} href={href.toString()} ref={ref}>
        {children}
      </RagnarClickable>
    );
  },
);
