'use client';

import { captureException, MentiError } from '@mentimeter/errors/sentry';
import type { AxiosRequestConfig } from 'axios';
import { createClientCreator } from '../createClientCreator';
import { createDedup } from './dedup';
import { getEditorHttpClient } from './editor-http-client';
import type { CoreClient, Regions } from './types';

export function createParams<
  T extends Record<string, string | number | boolean>,
>(params: T): string {
  const result = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');
  return result ? `?${result}` : '';
}

export const createCoreClient = createClientCreator<CoreClient>(
  (client, { defaultHeaders }) => {
    const { dedupGetRequest } = createDedup();
    return {
      ...getEditorHttpClient(client),
      getSeriesByVoteId(id: string) {
        return client.get(`/audience/series/${id}/vote-key`, {
          headers: defaultHeaders,
        });
      },
      integrations: {
        zoom: {
          get: (meetingId, config) => {
            return client.get(`/zoom-meetings/${meetingId}`, config);
          },
          put: (meetingId, data, config) => {
            return client.put(`/zoom-meetings/${meetingId}`, data, config);
          },
          delete: (meetingId, config) => {
            return client.delete(`/zoom-meetings/${meetingId}`, config);
          },
        },
        msteams: {
          connect(body, config) {
            return client.post('/integrations/msteams/connect', body, config);
          },
          authenticate(body, config) {
            return client.post(
              '/integrations/msteams/authenticate',
              body,
              config,
            );
          },
          region(body, config) {
            return client.post('/integrations/msteams/region', body, config);
          },
        },
      },
      campaigns: {
        get(config) {
          return client.get('/campaigns/trial-enterprise/preflight', config);
        },
        post(config) {
          return client.post('/campaigns/trial-enterprise/claim', config);
        },
      },
      convert: {
        get(id, config) {
          return client.get(`/convert/${id}`, config);
        },
        post(data, config) {
          return client.post('/convert', data, config);
        },
        importPreflight(data, config) {
          return client.post('/convert/import-preflight', data, config);
        },
      },
      duplicate: {
        questions(seriesId, data, config) {
          return client.post(
            `/duplicate/series/${seriesId}/questions`,
            data,
            config,
          );
        },
        series(data, config) {
          return client.post('/duplicate/series', data, config);
        },
      },
      themes: {
        getAll(config) {
          return client.get('/themes', config);
        },
        get(id, config) {
          return client.get(`/themes/${id}`, config);
        },
        post(data, config) {
          return client.post('/themes', data, config);
        },
        put(id, data, config) {
          return client.put(`/themes/${id}`, data, config);
        },
        delete(id, config) {
          return client.delete(`/themes/${id}`, config);
        },
      },
      files: {
        post(contentType, config) {
          return client.post(`/files`, { content_type: contentType }, config);
        },
      },
      images: {
        put(id, data, config) {
          return client.put(`/images/${id}`, data, config);
        },
      },

      folders: {
        get({ dynamicDashboard }, config) {
          return client.get(`/folders/${dynamicDashboard}`, config);
        },
        post(data, config) {
          return client.post('/home/folders', data, config);
        },
        put(id, data, config) {
          return client.put(`/home/folders/${id}`, data, config);
        },
      },
      questions: {
        get(id, config) {
          return client.get(`/questions/${id}`, config);
        },
        result: {
          get(id, params = {}, config) {
            return client.get(`/questions/${id}/result`, {
              params,
              ...config,
            });
          },
        },
        quizPlayers: {
          get(id, clearCache, config) {
            return client.get(`/questions/${id}/quiz-players`, {
              params: { clear_cache: clearCache },
              ...config,
            });
          },
          put(id, data, config) {
            return client.put(`/questions/${id}/quiz-players`, data, config);
          },
        },
        quizResult: {
          get(id, config) {
            return client.get(
              `/presentation/questions/${id}/quiz-result`,
              config,
            );
          },
        },
        sessions: {
          get(id, config) {
            return client.get(`/questions/${id}/sessions`, config);
          },
        },
      },
      series: {
        get(id, config) {
          if (typeof id !== 'string') {
            captureException(
              new MentiError(
                `Expected id to be string, received ${typeof id}.`,
                { feature: 'devops' },
              ),
            );
          }
          return dedupGetRequest(`/series/${id}`, client, config) as ReturnType<
            CoreClient['series']['get']
          >;
        },
        getFormattedForPresentation(id, config) {
          return client.get(`/series/${id}?for_presentation=true`, config);
        },
        getSeriesHasResults(seriesId, { questionIds, minRespondents } = {}) {
          return client.get(`/series/${seriesId}/has-results`, {
            params: {
              question_ids: questionIds,
              min_respondents: minRespondents,
            },
          });
        },
        post(data, config) {
          return client.post('/series', data, config);
        },
        put(id, data, config) {
          return client.put(`/series/${id}`, data, config);
        },
        realtime(seriesId, accessKey, config) {
          const params = accessKey ? { access_key: accessKey } : {};
          return client.post(
            `/series/${seriesId}/realtime-token`,
            {
              params,
            },
            config,
          );
        },
        importQuestions(seriesId, data, config) {
          return client.post(
            `/series/${seriesId}/import-questions`,
            data,
            config,
          );
        },
        questions: {
          delete(seriesId, data, config) {
            return client.delete(`/series/${seriesId}/questions`, {
              data,
              ...config,
            });
          },
          put(seriesId, questionId, data, config) {
            return client.put(
              `/series/${seriesId}/questions/${questionId}`,
              data,
              config,
            );
          },
          post(seriesId, data, config) {
            return client.post(`/series/${seriesId}/questions`, data, config);
          },
          image: {
            put(seriesId, questionId, data, config) {
              return client.put(
                `/series/${seriesId}/questions/${questionId}`,
                data,
                config,
              );
            },
          },
        },
        editor: {
          questions: {
            post(seriesId, data, config) {
              return client.post(
                `editor/series/${seriesId}/questions/insert`,
                data,
                config,
              );
            },
          },
        },
        collaborators: {
          getMe(seriesId, config) {
            if (typeof seriesId !== 'string') {
              captureException(
                new MentiError(
                  `Expected seriesId to be string, received ${typeof seriesId}`,
                  { feature: 'devops' },
                ),
              );
            }
            return client.get(
              `/series/${seriesId}/collaborators?only_me=true`,
              config,
            );
          },
          delete(seriesId, userId, userEmail, config) {
            const path = userId
              ? `/series/${seriesId}/collaborators?user_id=${userId}`
              : `/series/${seriesId}/collaborators?user_email=${encodeURIComponent(userEmail ?? '')}`;
            return client.delete(path, config);
          },
        },
        voteCode: {
          get(seriesId, config) {
            return client.get(`/series/${seriesId}/vote-code`, config);
          },
          post(seriesId, data, config) {
            return client.post(`/series/${seriesId}/vote-code`, data, config);
          },
        },
        sessions: {
          get(id, accessKey, config = {}) {
            return client.get(`/series/${id}/sessions`, {
              params: { access_key: accessKey },
              ...config,
            });
          },
          post(seriesId, data, config) {
            return client.post(`/series/${seriesId}/sessions`, data, config);
          },
          put(seriesId, sessionId, data, config) {
            return client.put(
              `/series/${seriesId}/sessions/${sessionId}`,
              data,
              config,
            );
          },
          delete(seriesId, sessionId, config) {
            return client.delete(
              `/series/${seriesId}/sessions/${sessionId}`,
              config,
            );
          },
        },
        tryModes: {
          post(seriesId, data, config) {
            return client.post(`/series/${seriesId}/try-modes`, data, config);
          },
        },
        template: {
          get(seriesId, config) {
            return client.get(`/series/${seriesId}/template`, config);
          },
          put(seriesId, data, config) {
            return client.put(`/series/${seriesId}/template`, data, config);
          },
        },
      },
      export: {
        get(id: string) {
          return client.get(`/export/${id}`);
        },
        post(id: string) {
          return client.post(`/export/${id}`);
        },
      },
      templates: {
        post(data, config) {
          return client.post(`/templates`, data, config);
        },
        put(id, data, config) {
          return client.put(`/templates/${id}`, data, config);
        },
      },
      templateEditor: {
        publicTemplates: {
          get(seriesId, config) {
            return client.get(
              `/template-editor/public-templates?series_id=${seriesId}`,
              config,
            );
          },
          post(data, config) {
            return client.post(
              `/template-editor/public-templates`,
              data,
              config,
            );
          },
          put(id, data, config) {
            return client.put(
              `/template-editor/public-templates/${id}`,
              data,
              config,
            );
          },
        },
      },
      publicTemplates: {
        get(queryString, config) {
          return client.get(`/public-templates${queryString || ''}`, config);
        },
        template: {
          get(id, config) {
            return client.get(`/public-templates/${id}`, config);
          },
        },
        categories: {
          post(data, config) {
            return client.post(`/public-templates/categories`, data, config);
          },
          put(id, data, config) {
            return client.put(
              `/public-templates/categories/${id}`,
              data,
              config,
            );
          },
          get(queryString, config) {
            return client.get(
              `/public-templates/categories${queryString || ''}`,
              config,
            );
          },
        },
        insert: {
          post(seriesId, data, config) {
            return client.post(
              `editor/series/${seriesId}/questions/insert-public-template`,
              data,
              config,
            );
          },
        },
        replace: {
          post(seriesId, questionId, data, config) {
            return client.post(
              `editor/series/${seriesId}/questions/${questionId}/replace-public-template`,
              data,
              config,
            );
          },
        },
      },
      ratingGrids: {
        get(config) {
          return client.get('/rating-grids/all', config);
        },
        post(data, config) {
          return client.post('/rating-grids', data, config);
        },
        put(id, data, config) {
          return client.put(`/rating-grids/${id}`, data, config);
        },
        delete(id, deviceId, config) {
          return client.delete(`/rating-grids/${id}`, {
            params: {
              device_id: deviceId,
            },
            ...config,
          });
        },
      },
      qfa: {
        get: {
          all(seriesId, page, config) {
            return client.get(
              `/series/${seriesId}/qfa/v2?page=${page}`,
              config,
            );
          },
          rejected(seriesId, page, config) {
            return client.get(
              `/series/${seriesId}/qfa?rejected=true&page=${page}`,
              config,
            );
          },
          archived(seriesId, page, config) {
            return client.get(
              `/series/${seriesId}/qfa?archived=true&page=${page}`,
              config,
            );
          },
          accepted(seriesId, page, config) {
            const url = `/series/${seriesId}/qfa?page=${page}`;
            return client.get(url, config);
          },
          pending(seriesId, page, config) {
            return client.get(
              `/series/${seriesId}/qfa?pending=true&page=${page}`,
              config,
            );
          },
        },
        delete(id, deviceId, config) {
          return client.delete(
            `/qfa/${id}${deviceId ? `?device_id=${deviceId}` : ''}`,
            config,
          );
        },
        deleteAll(seriesId, deviceId, config) {
          return client.delete(
            `/series/${seriesId}/qfa${
              deviceId ? `?device_id=${deviceId}` : ''
            }`,
            config,
          );
        },
        archive(id, deviceId, config) {
          return client.post(
            `/qfa/${id}/archive${deviceId ? `?device_id=${deviceId}` : ''}`,
            config,
          );
        },
        restore(id, deviceId, config) {
          return client.post(
            `/qfa/${id}/restore${deviceId ? `?device_id=${deviceId}` : ''}`,
            config,
          );
        },
        reject(id, deviceId, config) {
          return client.post(
            `/qfa/${id}/reject${deviceId ? `?device_id=${deviceId}` : ''}`,
            config,
          );
        },
        accept(id, deviceId, config) {
          return client.post(
            `/qfa/${id}/accept${deviceId ? `?device_id=${deviceId}` : ''}`,
            config,
          );
        },
      },
      users: {
        fetchUser(config) {
          return client.get('/users', config);
        },

        getUnreadNotifications(config) {
          return client.get('/users/notifications/unread', config);
        },

        getUserProfile(config) {
          return client.get('/users/profile', config);
        },

        updateUserProfile(payload, config) {
          return client.put('/users/profile', payload, config);
        },

        saveUtmFlags(payload, config) {
          return client.patch('/users', payload, config);
        },

        updateFlags(flags, config) {
          return client.put('/users', { flags }, config);
        },

        updateSettings(settings, config) {
          return client.put('/users', { settings }, config);
        },

        revokeSessions(config) {
          return client.post('/users/revoke-sessions', config);
        },

        delete(config) {
          return client.delete('/users', config);
        },

        profilePicture: {
          post(data, config) {
            return client.post('/users/profile-picture', data, config);
          },

          delete(config) {
            return client.delete('/users/profile-picture', config);
          },
        },
      },
      userOtp: {
        post(config) {
          return client.post('user-otp', config);
        },
      },

      subscriptions: {
        get(config) {
          return client.get('/subscriptions', config);
        },
      },

      pppPrices: {
        get(config) {
          return client.get('/payment-services/prices/self-service', config);
        },
      },

      prices: {
        get(config) {
          return client.get('/payment-services/prices', config);
        },
      },

      payments: {
        getPaymentMethods(config) {
          return client.get('/payment-services/payment-methods', config);
        },

        generateCheckoutSession(payload, config) {
          return client.post(
            '/payment-services/checkout/sessions',
            payload,
            config,
          );
        },

        getTransactions(config) {
          return client.get('/subscriptions/transactions', config);
        },

        getReceipt(id, config) {
          return client.get(`/subscriptions/receipt/${id}`, config);
        },
      },
      workspaces: {
        settings: {
          get(config) {
            return client.get('/workspaces/settings', config);
          },
          anyoneCanInvite: {
            put(payload, config) {
              return client.put(
                '/workspaces/settings/anyone-can-invite',
                payload,
                config,
              );
            },
          },
          updateDiscoverable(id, payload, config) {
            return client.put(
              `/workspaces/settings/discoverable`,
              payload,
              config,
            );
          },
        },
        pendingMemberships: {
          consume(token, config) {
            return client.post(
              `/workspaces/pending-memberships/${token}/consume`,
              config,
            );
          },
          claim(payload, config) {
            return client.post(
              `/workspaces/pending-memberships/claim`,
              payload,
              config,
            );
          },

          post(workspaceId, payload, config) {
            return client.post(
              `/workspaces/${workspaceId}/pending-memberships`,
              payload,
              config,
            );
          },
          delete(token, config) {
            return client.delete(
              `/workspaces/pending-memberships/${token}`,
              config,
            );
          },
          get(token, config) {
            return client.get(
              `/workspaces/pending-memberships/${token}`,
              config,
            );
          },
        },
        folders: {
          post(payload, config) {
            return client.post(`/workspaces/folders`, payload, config);
          },
          put(id, payload, config) {
            return client.put(`/workspaces/folders/${id}`, payload, config);
          },
        },
        getActiveWorkspace(config) {
          return client.get('/workspaces/active', config);
        },

        getWorkspacesByDomain(config) {
          return client.get('/workspaces/by-domain', config);
        },

        getWorkspaceByUrl(url, config) {
          return client.get(`/workspaces?url=${url}`, config);
        },

        updateWorkspace(id, payload, config) {
          return client.put(`/workspaces/${id}`, payload, config);
        },
        post(payload, config) {
          return client.post(`/workspaces`, payload, config);
        },
        controlledDomains: {
          getAll(config: AxiosRequestConfig) {
            return client.get(`/workspaces/controlled-domains`, config);
          },
          getVerified(config) {
            return client.get(
              `/workspaces/controlled-domains/verified`,
              config,
            );
          },
          add(payload, config) {
            return client.patch(
              `/workspaces/controlled-domains`,
              payload,
              config,
            );
          },
          delete(domainId, config) {
            return client.delete(
              `/workspaces/controlled-domains/${domainId}`,
              config,
            );
          },
          resendVerification(payload, config) {
            return client.post(
              `/workspaces/controlled-domains/resend-verification`,
              payload,
              config,
            );
          },
        },

        members: {
          summary: {
            get(queryString, config) {
              return client.get(
                `workspaces/members/summary${queryString}`,
                config,
              );
            },
          },

          suspend(memberId, config) {
            return client.post(
              `/workspaces/members/${memberId}/suspend`,
              config,
            );
          },

          reinvite(memberId, config) {
            return client.post(
              `/workspaces/members/${memberId}/reinvite`,
              config,
            );
          },

          downgrade(memberId, config) {
            return client.post(
              `/workspaces/members/${memberId}/downgrade`,
              config,
            );
          },
          updateRole(memberId, payload, config) {
            return client.post(
              `/workspaces/members/${memberId}/update_role`,
              payload,
              config,
            );
          },
        },

        exportMembers: {
          get(exportId, config) {
            return client.get(`/workspaces/export-members/${exportId}`, config);
          },
          post(workspaceId, queryString, config) {
            return client.post(
              `/workspaces/${workspaceId}/export-members${queryString}`,
              config,
            );
          },
        },
        exportNonMembers: {
          get(exportId, config) {
            return client.get(
              `/workspaces/export-non-members/${exportId}`,
              config,
            );
          },
          post(queryString, config) {
            return client.post(
              `/workspaces/export-non-members${queryString}`,
              config,
            );
          },
        },
        requestUpgrade(message, config) {
          return client.post(
            '/workspaces/request-upgrade',
            { message },
            config,
          );
        },
      },

      groups: {
        getAll({ isAdmin }) {
          return client.get(`/groups?admin=${String(isAdmin)}`);
        },
        get({ id }) {
          return client.get(`/groups/${id}`);
        },
        update({ id, ...rest }) {
          return client.put(`/groups/${id}`, rest);
        },
        delete({ id }) {
          return client.delete(`/groups/${id}`);
        },
        create({ name, description }) {
          return client.post('/groups', { name, description });
        },
        getMembers({ id }) {
          return client.get(`/groups/${id}/members`);
        },
        members: {
          delete({ id, members }) {
            return client.post(`/groups/${id}/members/delete`, { members });
          },
          add({ id, members }) {
            return client.post(`/groups/${id}/members`, {
              members,
            });
          },
        },
      },

      apiTokens: {
        getAll(config) {
          return client.get('/api-tokens', config);
        },
        generate(config) {
          return client.post('/api-tokens', config);
        },
        revoke(token, config) {
          return client.delete(`/api-tokens/${token}`, config);
        },
      },

      region: {
        urls(region: Regions, config) {
          return client.get(`/region/urls/${region}`, config);
        },
        workspaces(hash: string, config) {
          return client.get(`/region/workspaces/${hash}`, config);
        },
      },
      auth: {
        saml: {
          confirm({ token }, config) {
            return client.post('/saml/confirm', { token }, config);
          },
        },
        resetPassword(email, config) {
          return client.post('/users/reset_passwords', { email }, config);
        },
        verifyResetPasswordCode(code, config) {
          return client.get(`/users/reset_passwords/${code}/verify`, config);
        },
        changePasswordWithCode({ email, password, code }, config) {
          return client.post(
            `/users/reset_passwords/${code}`,
            { email, password },
            config,
          );
        },
      },
      moderation: ({ questionId, seriesId }) => ({
        get(config) {
          return client.get(
            `/moderate/blacklisted?admin_key=${questionId}&series=${seriesId}`,
            config,
          );
        },
        toggle(answer, config) {
          return client.post(
            `/moderate/blacklist?admin_key=${questionId}&series=${seriesId}`,
            { answer },
            config,
          );
        },
        clear(config) {
          return client.put(
            `/moderate/clear?admin_key=${questionId}&series=${seriesId}`,
            {},
            config,
          );
        },
      }),
      diagnostics: {
        get(config) {
          return client.get('/request-test', config);
        },
        put(config) {
          return client.put('/request-test', {}, config);
        },
      },
      academy: {
        get(config) {
          return client.get('/idp/mentiacademy', config);
        },
      },
      emailVerification: {
        verify: (payload, config) => {
          return client.post('/email-verification/verify', payload, config);
        },
        resend: (config) => {
          return client.post('/email-verification/resend', config);
        },
      },
      presentation: {
        reactions: {
          results: {
            get: (questionId, config) => {
              return client.get(
                `/presentation/reactions/${questionId}/results`,
                config,
              );
            },
          },
        },
      },
      presentations: {
        me: {
          get: (params, config) => {
            return client.get(`/presentation-collection/me`, {
              params,
              ...config,
            });
          },
        },
        sharedWithMe: {
          get: (params, config) => {
            return client.get(`/presentation-collection/shared-with-me`, {
              params,
              ...config,
            });
          },
        },
        workspace: {
          get: (params, config) => {
            return client.get(`/presentation-collection/workspace`, {
              params,
              ...config,
            });
          },
        },
      },
      responseClusterings: {
        post: (payload, config) => {
          return client.post(`/response-clusterings`, payload, config);
        },
        assignResponse: (clusterId, payload, config) => {
          return client.post(
            `response-clusters/${clusterId}/assign-responses`,
            payload,
            config,
          );
        },
        clusters: {
          post: (clusteringId, payload, config) => {
            return client.post(
              `response-clusterings/${clusteringId}/clusters`,
              payload,
              config,
            );
          },
          put: (cluster, config) => {
            return client.put(
              `response-clusters/${cluster.id}`,
              cluster,
              config,
            );
          },
        },
      },
      resultsPage: {
        post(data, config) {
          return client.post(`/results-page`, data, config);
        },
      },
      admin: {
        getAdminUser(config) {
          return client.get('/admin/admin-user', {
            withCredentials: true,
            ...config,
          });
        },
        auth: {
          devLogin(config) {
            return client.get('/admin/auth/dev-login', {
              withCredentials: true,
              ...config,
            });
          },
        },
      },
    };
  },
);
