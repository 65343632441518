import { clsx, type ClassValue } from 'clsx';
import { extendTailwindMerge, validators } from 'tailwind-merge/es5';

const twMerge = extendTailwindMerge({
  override: {
    classGroups: {
      opacity: [
        { opacity: [validators.isNumber, validators.isArbitraryValue] },
      ],
      'font-size': [
        {
          text: [
            validators.isNumber,
            validators.isArbitraryLength,
            (classPart: string) => /size-inherit/.test(classPart),
          ],
        },
      ],
    },
    conflictingClassGroups: {
      // In our tailwind.config we override the default font-size scale without line-height
      // I.e. font-size should no longer conflict with line-height
      // https://tailwindcss.com/docs/font-size
      'font-size': [],
    },
  },
  extend: {
    classGroups: {
      // We want to allow DEFAULT values for text and background color
      'text-color': [{ text: [''] }],
      'bg-color': [{ bg: [''] }],
      'text-alignment': [{ text: ['initial'] }],
      z: [{ z: ['drawer', 'modal', 'notification', 'sticky'] }],
      leading: [{ leading: ['inherit'] }],
      'list-style-type': [
        {
          list: [
            'none',
            'disc',
            'dec',
            'upper-roman',
            'lower-roman',
            'upper-alpha',
            'lower-alpha',
          ],
        },
      ],
      animate: [
        {
          animate: [
            'fade-in',
            'float',
            'fade-in-bottom',
            'fade-in-top',
            'fade-in-left',
            'fade-in-right',
            'fade-out-right',
            'fade-out-left',
            'fade-out-bottom',
            'fade-out-top',
          ],
        },
      ],
    },
  },
});

export function cn(...classes: ClassValue[]) {
  return twMerge(clsx(classes));
}
