import * as React from 'react';
import type { Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import { combineRules } from '@mentimeter/ragnar-react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import type { BoxT } from '../box';
import { rule as boxRule } from '../box';

// Inherit the API from Box, but add some props
export interface ClickableA
  extends Pick<BoxT, 'bg' | 'borderRadius' | 'borderColor'> {
  href?: string | undefined;
  type?: 'submit' | 'button' | 'reset';
  disabled?: boolean | undefined;
  target?: '_blank' | undefined;
  rel?: 'noopener noreferrer';
  title?: string;
}

const clickableRule: Rule<ClickableA> = () => ({});

const FelaClickable = styled('button', {
  displayName: 'ClickableC',
})(
  combineRules(boxRule, clickableRule),
  'color',
  'display',
  'height',
  'minHeight',
  'maxHeight',
  'width',
  'minWidth',
  'maxWidth',
  'overflow',
  'flex',
  'flexDirection',
  'flexWrap',
  'alignContent',
  'alignItems',
  'justifyContent',
  'justifyItems',
  'position',
  'kosmosSpacing',
  'kosmosBorderRadius',
  'kosmosGrid',
);

export const Clickable = React.forwardRef<HTMLElement, ClickableT>(
  (props, ref) => {
    const { disabled, href, onClick, target, as, className, ...rest } = props;
    const defaultClasses = [
      'r-box',
      'flex',
      'flex-col',
      'flex-none',
      'w-auto',
      'items-start',
      'min-w-0',
      'min-h-0',
      'relative',
      'rounded',
      'bg-transparent',
      'no-underline',
      'appearance-none',
      'transition',
      'transition-all',
      'ease-in-out',
      'duration-[0.1s]',
      'cursor-pointer',
      'hover:opacity-60',
      'outline-transparent',
      '[&:enabled]:focus-visible:outline',
      '[&:enabled]:focus-visible:outline-interactive-focused',
      '[&:enabled]:focus-visible:outline-offset-2',
      '[&:enabled]:focus-visible:outline-4',
      '[&:disabled]:cursor-not-allowed',
      '[&:disabled]:opacity-40',
      '[&:disabled]:hover:opacity-40',
    ];

    if (href !== undefined) {
      return (
        <FelaClickable
          {...rest}
          ref={ref}
          href={disabled ? undefined : href}
          aria-disabled={disabled}
          onClick={disabled ? undefined : onClick}
          as={disabled ? 'span' : (as ?? 'a')}
          role={disabled ? 'link' : undefined}
          target={disabled ? undefined : target}
          className={cn(defaultClasses, className)}
        />
      );
    }
    return (
      <FelaClickable
        ref={ref}
        tabIndex={!as || as === 'button' ? undefined : 0}
        {...rest}
        as={as ?? 'button'}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        className={cn(defaultClasses, className)}
      />
    );
  },
);

export type ClickableT = React.ComponentProps<typeof FelaClickable>;
