import * as React from 'react';
import { useTransition, animated } from 'react-spring';
import { useRagnar } from '@mentimeter/ragnar-react';
import { toRgb } from '@mentimeter/ragnar-colors';
import { Backdrop } from '../backdrop';
import { PortalPositioner, PortalOld } from '../portal';

export interface ModalGenericT {
  show: boolean;
  center?: boolean | undefined;
  zIndex?: number;
  onDismiss?: undefined | (() => void);
  children: React.ReactNode;
}

/**
 * @deprecated Use the new accessible modal components instead. [Link to Storybook](https://storybook.mentimeter.org/?path=/docs/global-system-ragnar-ui-modal--docs)
 */

const ModalGeneric = ({
  show: shouldShow,
  center = false,
  onDismiss,
  children,
  zIndex,
}: ModalGenericT) => {
  const { theme: dsc } = useRagnar();
  // Determine the background color of the backdrop
  const { r, g, b } = toRgb(dsc.colors.bgOverlay);
  const transition = useTransition(shouldShow, {
    from: { scale: 0.8, opacity: 0, backgroundOpacity: 0 },
    enter: { scale: 1, opacity: 1, backgroundOpacity: dsc.opacity.faded },
    leave: { scale: 0.8, opacity: 0, backgroundOpacity: 0 },
  });

  return (
    <PortalOld domId="modal-portal">
      {transition((styles, isShowing) => {
        const content = (
          <>
            <animated.div
              aria-label="Modal"
              role="dialog"
              aria-modal="true"
              aria-live="polite"
              style={{
                zIndex: 2,
                transform: styles.scale.to((s) =>
                  s === 1 ? 'none' : `scale(${s})`,
                ) as unknown as string,
                opacity: styles.opacity,
              }}
            >
              {children}
            </animated.div>
            {/* Used as click handler for background click */}

            <Backdrop
              data-testid="modal-backdrop"
              onClick={onDismiss}
              style={{
                opacity: 0,
              }}
            />
          </>
        );
        return (
          isShowing && (
            <PortalPositioner
              as={animated.div}
              center={center}
              zIndex={zIndex || dsc.zIndex.modal}
              style={{
                // When the modal is closed, we immediately want to be able to click things behind it
                pointerEvents: shouldShow ? 'auto' : 'none',
                background: styles.backgroundOpacity.to(
                  (bo) => `rgba(${r},${g},${b},${bo}`,
                ) as unknown as string,
              }}
            >
              {content}
            </PortalPositioner>
          )
        );
      })}
    </PortalOld>
  );
};

export default ModalGeneric;
