/** TODO:
 * Remove trends and sessions
 * Look through the usage of the functions and make their purpose more clear
 *    - Split the functions to have a specific purpose
 * Why do we need the PresentationRoute interface?
 * Do we need all the ifs in buildPresentationPath?
 */

import { MentiError } from '@mentimeter/errors/sentry';

const validRoutes = [
  'edit',
  'embed',
  'follower',
  'integrated',
  'invite',
  'mentimote',
  'powerpoint-addin',
  'participant',
  'present',
  'present-preview',
  'results',
  'screenshot',
  'screenshot-image',
  'screenshot-pdf',
  'template',
  'view',
] as const;

type ExtractArrayElementType<T extends readonly any[]> =
  T extends readonly (infer U)[] ? U : never;

export type PresentationMode = ExtractArrayElementType<typeof validRoutes>;

export interface PresentationRoute {
  seriesId: string;
  questionId?: string | undefined | null;
  mode: PresentationMode;
  session?: 'trends' | number | null;
  currentSearchParams?: URLSearchParams | undefined;
}

export function buildPresentationPath(route: PresentationRoute) {
  const searchParams = new URLSearchParams(
    route.currentSearchParams || undefined,
  );
  if (route.questionId) {
    searchParams.set('question', route.questionId);
  }

  const queryWithQuestionId = searchParams.toString();
  const queryStringWithQuestionId =
    queryWithQuestionId.length > 0 ? `?${queryWithQuestionId}` : '';

  if (isSessionValid(route.session)) {
    searchParams.set('session', String(route.session));
  }
  const queryWithQuestionIdAndSession = searchParams.toString();
  const queryStringWithQuestionIdAndSession =
    queryWithQuestionIdAndSession.length > 0
      ? `?${queryWithQuestionIdAndSession}`
      : '';

  const modePaths: { [key: string]: string } = {
    view: `/view${queryStringWithQuestionId}`,
    results: `/results`,
    follower: `/follower`,
    screenshot: `/screenshot/view?question=${route.questionId}`,
    'screenshot-image': `/screenshot/image${queryStringWithQuestionId}`,
    edit: `/edit${queryStringWithQuestionId}`,
    embed: `/embed${queryStringWithQuestionIdAndSession}`,
    present: `/present${queryStringWithQuestionIdAndSession}`,
    mentimote: `/mentimote`,
    template: `/template${queryStringWithQuestionIdAndSession}`,
    integrated: `/integrated${queryStringWithQuestionId}`,
    'powerpoint-addin': `/powerpoint-addin${queryStringWithQuestionId}`,
    'present-preview': `/present-preview${queryStringWithQuestionId}`,
    invite: `/invite${queryStringWithQuestionId}`,
    participant: `/participant${queryStringWithQuestionId}`,
  };

  const path = modePaths[route.mode];

  if (path) {
    return `/app/presentation/${route.seriesId}${path}`;
  } else {
    throw new Error(`Unknown mode: ${route.mode}`);
  }
}

/**
 * Parse url fragments to info about presentation
 * @param pathname Pathname **excluding** the search parameters
 * @param searchParams search parameters, or null
 */
export function parsePresentationPathAndSearchParams(
  pathname: string,
  searchParams: URLSearchParams | null,
): PresentationRoute {
  const pathParts = pathname.replace(/\/$/, '').split('/');

  const [, , , seriesId, view] = pathParts;
  if (!validRoutes.includes(view as PresentationMode)) {
    throw new MentiError(
      `Invalid presentation URL: view: ${view} for pathname: ${pathname}`,
      {
        feature: 'presentation-url',
      },
    );
  }

  const questionId = searchParams?.get('question');
  const session = searchParams?.get('session');

  const route: PresentationRoute = {
    seriesId: seriesId || '',
    mode: view as PresentationMode,
  };

  if (questionId) route.questionId = questionId;

  if (session) {
    route.session = isNaN(Number(session))
      ? ((session as PresentationRoute['session']) ?? null)
      : Number(session);
  }
  return route;
}

export function parsePresentationUrl(href: string): PresentationRoute {
  const url = new URL(href);
  return parsePresentationPathAndSearchParams(url.pathname, url.searchParams);
}

export function parsePresentationSession(
  sessionParam: string | undefined | null,
) {
  if (!sessionParam) return null;
  if (sessionParam === 'trends') {
    return 'trends';
  }
  const parsedSessionNumber = Number(sessionParam);
  if (isSessionValid(parsedSessionNumber)) return parsedSessionNumber;
  return null;
}

function isSessionValid(session: PresentationRoute['session']) {
  return session === 'trends' || Number.isInteger(session);
}

/**
 * Merges relative PresentationRoute changes with the current route for easy navigation,
 * for example when navigating to another question within the same presentation.
 */
export function getPresentationPathRelative(route: Partial<PresentationRoute>) {
  const currentPresentationRoute = parsePresentationUrl(window.location.href);

  const currentUrl = new URL(window.location.href);
  const newPath = buildPresentationPath({
    ...currentPresentationRoute,
    ...route,
  });

  // No current query params, just return the new path
  if (currentUrl.search.length <= 0) return newPath;

  const searchStringStartIndex = newPath.indexOf('?');

  // We only want to inherit query params other than the ones controlled
  // by the route, so remove the session param.
  currentUrl.searchParams.delete('session');

  // No new query params, add the current params to the new path
  if (searchStringStartIndex < 0) return newPath + currentUrl.search;

  // Everything up until the ? is the path...
  const pathBase = newPath.slice(0, searchStringStartIndex);
  // ... everything after is the query params
  const pathSearch = newPath.slice(searchStringStartIndex);

  // Merge current and new params, with the new ones taking precedence
  const newSearchParams = new URLSearchParams(pathSearch);
  const mergedParams = new URLSearchParams({
    ...Object.fromEntries(currentUrl.searchParams),
    ...Object.fromEntries(newSearchParams),
  });

  return `${pathBase}?${mergedParams}`;
}
