import * as React from 'react';
import { ThemeProvider } from '@mentimeter/ragnar-react';
import { type Colors } from '@mentimeter/ragnar-colors';
import { designSystemConfig, type Fonts } from '@mentimeter/ragnar-dsc';
import { themes } from '@mentimeter/ragnar-colors';

export interface ThemeT {
  theme: { colors: Colors; fonts?: Fonts };
  themeClass?: string | undefined;
}

export function Theme({
  theme,
  themeClass,
  children,
}: React.PropsWithChildren<ThemeT>) {
  return (
    <ThemeProvider theme={theme} themeClass={themeClass}>
      {children}
    </ThemeProvider>
  );
}

export function Light({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.light} themeClass="light">
      {children}
    </Theme>
  );
}

export function Dark({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.dark} themeClass="dark">
      {children}
    </Theme>
  );
}

export function Primary({ children }: { children: React.ReactNode }) {
  return (
    <Theme theme={themes.primary} themeClass="primary">
      {children}
    </Theme>
  );
}

export function ThemeMentiLight({ children }: { children: React.ReactNode }) {
  return (
    <Theme
      theme={{
        colors: themes.light.colors,
        fonts: designSystemConfig.fonts,
      }}
      themeClass="light"
    >
      {children}
    </Theme>
  );
}
