import { mostSpecificCause } from '@mentimeter/errors/cause';
import { isAxiosError } from '@mentimeter/errors/is-axios';
import type { ErrorEvent, EventHint } from '@sentry/nextjs';

export function dashboardErrorMiddleware(
  event: ErrorEvent,
  _hint: EventHint,
  originalException: Error,
): ErrorEvent | null {
  const specificError = mostSpecificCause(originalException);

  if (isAxiosError(specificError)) {
    return null;
  }

  return event;
}
